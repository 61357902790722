var prefCountry;
var prefLanguage;

(function ($, generic, site) {
  Drupal.behaviors.countrypicker = {
    attach: function (context, settings) {
      var locale;
      var cartItemCount = 0;

      locale = generic.cookie('LOCALE'); // get locale settings from browser if it's set
      if (locale != null && locale !== '') {
        var localeParts = locale.split('_');

        prefLanguage = localeParts[0].toLowerCase();
        prefCountry = localeParts[1].toLowerCase();
        site.setupLanguageMenu($('#countryPickerWrap'), prefCountry, prefLanguage);

        $('#countryPickerWrap .language-list li').on('click', function (e) {
          e.preventDefault();
          site.changeLocale(prefCountry, $(this).data('language'));
        });
      }

      $('#countryPickerWrap .country-list a').off('click');

      $('#countryPickerWrap .country-list a').each(function () {
        $(this).bind('click', function () {
          // user may be switching country. is their current language going to be supported when they land or should we choose the target country's default?
          prefLanguage = site.getAltLanguage(prefLanguage, $(this).data('languages').split(','));
          requestChangeLocale($(this).data('country'), prefLanguage, $(this).data('countryname'));
        });
      });

      $('#mobileCountryLink').off('click');

      $('#mobileCountryLink').on('click', function () {
        // mobile-specific country link has different behaviour
        requestChangeLocaleMobile($(this).data('countryname'));
      });

      $(document).bind('cart.loaded', function (e, cartResponse) {
        cartItemCount = cartResponse.items_count;
      });

      function requestChangeLocale(prefC, prefL, countryName) {
        prefCountry = prefC;
        prefLanguage = prefL;

        if (cartItemCount > 0) {
          // if cart has items, show the warning
          $('#localeSwitchWarning').html(
            $('#localeSwitchWarning')
              .html()
              .replace('{{{country_name}}}', '<span id="#destinationCountryname">' + countryName + '</span>')
          );
          $('#destinationCountryname').html(countryName);

          $('body').append($('#localeSwitchOverlay').css({ display: 'block' }));
          site.centerModal($('#localeSwitchWarning'));
        } else {
          // if not, change straight away
          site.changeLocale(prefCountry, prefLanguage);
        }
      }

      function requestChangeLocaleMobile(countryName) {
        if (cartItemCount > 0) {
          // if cart has items, show the warning
          $('#localeSwitchWarning').html(
            $('#localeSwitchWarning')
              .html()
              .replace('{{{country_name}}}', '<span id="#destinationCountryname">' + countryName + '</span>')
          );
          $('#destinationCountryname').html(countryName);

          $('body').append($('#localeSwitchOverlay').css({ display: 'block' }));
          site.centerModal($('#localeSwitchWarning'));
        } else {
          // if not, show the interstitial
          $(document).trigger('displayLocaleInterstitial');
        }
      }
    }
  };
})(
  (window.jQuery = window.jQuery || function () {}),
  (window.generic = window.generic || {}),
  (window.site = window.site || {})
);
